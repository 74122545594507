import React, { ReactNode, Fragment, HTMLAttributes, useState } from 'react';
import { useIntlContext } from '../../locale/IntlProviderWrapper';
import {
  HeaderContainer,
  HeaderWrapper,
  Label,
  LinkItem,
  Navigation,
  PreContainer,
  HamburgerIcon,
  MobileMenu,
  MobileMenuWrapper,
  Logo
} from './Header.styles';
import LanguageSwitcher from './LanguageSwitcher';
import { formatMessage } from '../../locale';
import ProfileCircle from './ProfileCircle';
import CompanySelector from './CompanySelector';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { ASIDE_NAV } from '../../constants/asideNav';

export interface ActionItem {
  caption: string;
  link?: string;
  type: 'button' | 'label' | 'link' | 'heading' | 'dropdown';
  icon?: string;
  onClick?: () => void;
}

interface HeaderProps extends HTMLAttributes<HTMLHeadingElement> {
  logoWidth?: number;
  logoHeight?: number;
  navigation?: ActionItem[];
  onLogoClick?: () => void;
  logoMVV?: boolean;
}

const Header = ({
                  logoWidth = 108,
                  logoHeight = 60,
                  navigation,
                  onLogoClick,
                  logoMVV,
                }: HeaderProps) => {
  const { intl } = useIntlContext();
  const { userInfo, userCompany,permissions  } = useSelector((state: RootState) => state.auth);
  const canDo = (permission: string) => { return permissions && !!permissions[permission] };

  const [menuOpen, setMenuOpen] = useState(false); 

  const renderActionItem = (item: ActionItem): ReactNode => {
    switch (item.type) {
      case 'link':
        return (
          <LinkItem onClick={item.onClick}>
            {formatMessage({ id: 'menu.' + item.caption, defaultMessage: item.caption })}
          </LinkItem>
        );
      case 'dropdown':
        return <Label>{item.caption}</Label>;
      default:
        return null;
    }
  };

  const filteredNav = Object.entries(ASIDE_NAV).reduce((acc, [key, value]) => {
    acc[key] = value.filter((item:any) => canDo(item.permission));
    return acc;
  }, {} as { [key: string]: any[] });

  const color = userCompany?.main_color || '#A4366D';
  return (
    <HeaderWrapper>
      <PreContainer>
        <Logo onClick={onLogoClick}>
          <img src={userCompany?.logo?.url ? userCompany?.logo?.url : '/assets/logo.svg'} alt="Logo" />
        </Logo>
        <HamburgerIcon onClick={() => setMenuOpen(!menuOpen)}>
          &#9776;
        </HamburgerIcon>
        <HeaderContainer>
          <Navigation color={color}>
            {navigation?.map((actionItem) => (
              <Fragment key={actionItem.caption}>
                {renderActionItem(actionItem)}
              </Fragment>
            ))}
          </Navigation>
        </HeaderContainer>
        <div className="desktop-only">
          {canDo('core: change company') ? <div style={{alignSelf :'center'}}><CompanySelector /></div> : <div style={{ width: 300, marginRight: 20, alignSelf :'center'}}/>}
          <LanguageSwitcher />
          <ProfileCircle name={userInfo?.data?.firstname + ' ' + userInfo?.data?.lastname ?? ''} />
        </div>
      </PreContainer>
      <MobileMenuWrapper isOpen={menuOpen}>
        <MobileMenu>
          {navigation?.map((actionItem) => (
            <Fragment key={actionItem.caption}>
              {renderActionItem(actionItem)}
            </Fragment>
          ))}
          {Object.entries(filteredNav).map(([key, value]) => (
            <Fragment key={key}>
              {value.map((item: any) => (
                <LinkItem key={item.name} href={item.path}>
                  {formatMessage({ id: 'side_menu.' + item.name, defaultMessage: item.name })}
                </LinkItem>
              ))}
            </Fragment>
          ))}
          {canDo('core: change company') && <CompanySelector />}
          <LanguageSwitcher />
          <ProfileCircle name={userInfo?.data?.firstname + ' ' + userInfo?.data?.lastname ?? ''} />
        </MobileMenu>
      </MobileMenuWrapper>
    </HeaderWrapper>
  );
};

export default Header;
