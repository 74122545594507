import React, { useEffect } from 'react';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { setCompanyId } from '../../slices/companySlice';
import { useGetAllCompaniesQuery } from '../../slices/companiesApiSlice';
import { RootState } from '../../store';
import { useGetUserInfoQuery } from '../../slices/usersApiSlices';
import { formatMessage } from '../../locale';
import { useIntlContext } from '../../locale/IntlProviderWrapper';
interface Company {
  id: number;
  name: string;
}

const CompanySelector: React.FC = () => {
  const { intl } = useIntlContext();
  const dispatch = useDispatch();
  const selectedCompanyId = useSelector((state: RootState) => state.company.companyId);
  const { userCompany } = useSelector((state: RootState) => state.auth);
  const { data: companies, error, isLoading, refetch } = useGetAllCompaniesQuery({});
 const { data: user } = useGetUserInfoQuery({ });
  useEffect(() => {
   
    const storedCompanyId = localStorage.getItem('selectedCompanyId');
    if (storedCompanyId) {
      dispatch(setCompanyId(Number(storedCompanyId)));
    } else {
      dispatch(setCompanyId(userCompany.id));
    }
  }, [dispatch]);

  const handleChange = (selectedOption: { value: number; label: string } | null) => {
    const companyId = selectedOption ? selectedOption.value : null;
    dispatch(setCompanyId(companyId));
    if (companyId !== null) {
      localStorage.setItem('selectedCompanyId', companyId.toString());
    } else {
      localStorage.removeItem('selectedCompanyId');
    }
    window.location.reload();
  };

  const userCompanyId = user?.data?.company?.id;

  const options = [
    ...(userCompanyId ? [{
      value: userCompanyId,
      label: user?.data?.company?.name,
    }] : []),
    ...(companies?.data?.filter((company: Company) => company.id !== userCompanyId).map((company: Company) => ({
      value: company.id,
      label: company.name,
    })) || [])
  ];
  const customStyles = {
    control: (provided: any) => ({
      ...provided,
      width: 300,
      marginRight: 20,
    }),
  };

  if (isLoading) return <div>{formatMessage({ id: 'loading.loading', defaultMessage: 'Loading...' })}</div>;
  if (error) return <div>{formatMessage({ id: 'loading.errorLoadingCompany', defaultMessage: 'Error while loading company' })}</div>;

  return (
    <Select
      value={options.find(option => option.value === selectedCompanyId)}
      options={options}
      onChange={handleChange}
      placeholder="Select a company..."
      isSearchable
      styles={customStyles}
    />
  );
};

export default CompanySelector;
