import { error } from 'console'
import { Errors } from './Errors'
export const en = {
  Error: {
    ...Errors,
  },
  ...{
    menu: {
      company: 'Company',
      handbook: 'Handbook',
      courses: 'Courses',
      library: 'Library',
      logout: 'Logout',
    },

    side_menu: {
      Activities: 'Activities',
      Organization: 'Organization',
      virtualAssistant: 'AI Assistant',
      instructions: 'Instructions for the platform',
      Support: 'Support',
      Archive: 'Archive',
      actionsPlan: 'Actions',
      claims: 'Reporting',
      investigations: 'Risk assessment',
      dashboard: 'Dashboard',
      annualPlan: 'Annual plan',
      profile: 'Profile',
      users: 'Users',
      lessons: 'Lessons',
    },

    readMore: 'Read more »',
    confirm: 'Confirm',
    cancel: 'Cancel',

    annualPlan: {
      uploadAnnualPlan: 'Upload Annual Plan',
      upload: 'Upload',
      cancel: 'Cancel',
    },

    toolTip: {
      edit: 'Edit',
      copy: 'Duplicate',
      copySubsidiary: 'Copy to subsidiary',
      start: 'Start',
      delete: 'Delete',
    },

    confirmModal: {
      noticeCourse: 'Are you sure you want to delete this course?',
      noticeLesson: 'Are you sure you want to delete this lesson?',
    },

    dashboard: {
      myCourses: 'My courses',
      courseStatistics: 'Course Statistics',
      performanceStatistics: 'Performance',
      completed: 'Completed',
      inProgress: 'In progress',
      toBegin: 'To begin',
      completedSuccessfully: 'Completed Successfully',
      completedUnsuccessfully: 'Completed Unsuccessfully',
    },

    courseCard: {
      continue: 'CONTINUE',
      start: 'START',
      quiz: 'START QUIZ',
      review: 'REVIEW',
      startDate: 'Start date',
      endDate: 'Expiry date',
      lessons: 'Lessons',
      attempts: 'Attempts',
      completed: 'COMPLETED!',
      failed: 'FAILED!',

    },

    filters: {
      withExpiry: 'Courses with expiry date',
      withoutExpiry: 'Courses without expiry date',
      quickFilter: 'Quick filter',
    },

    clientCourses: {
      backToCourseList: 'Back to course list',
      publishedBy: 'Published by',
      publicationDate: 'Publication date',
      lessons: 'Lessons',
      completed: 'Completed',
      goToLesson: 'Go to lesson',
      startQuiz: 'Start quiz',
    },

    handbook: {
      searchPlaceholder: 'Search',
      newHandbookButton: 'New page',
      goBackHandbook: 'BACK TO HANDBOOK',
    },

    handbookForm: {
      edit: 'Edit: ',
      create: 'Create page',
      cancel: 'Cancel',
      publish: 'Publish',
      save: 'Save',
      titleLabel: 'Title',
      titlePlaceholder: 'Enter a title',
      descriptionLabel: 'Description',
      descriptionPlaceholder: 'Add a short description',
      contentLabel: 'Content',
      contentPlaceholder: 'Enter content',
      cardColorLabel: 'Card color',
      cardColorPlaceholder: 'Enter a card color',
      departmentLabel: 'Department/Business Unit/Team',
      officeLabel: 'Office',
      jobTitleLabel: 'Job Title',
      groupProjectLabel: 'Project group',
      userLabel: 'User',
      checkBox: 'All users',
      placeholderMultiSelect: 'Select...',
    },

    profileCircle: {
      profile: 'Profile',
      logout: 'Logout',
    },

    department: {
      noDepartment: 'No parent department',
      createTitle: 'Create Department',
      editTitle: 'Edit Department',
      nameLabel: 'Department Name *',
      parentLabel: 'Parent Department',
      companyLabel: 'Company',
      headLabel: 'Head of Business Unit / Team',
      errorLoadingUsers: 'Error loading users',
      errorLoadingDepartments: 'Error loading departments',
      departmentLabel: 'Department/Business Unit/Team',
    },

    company2: {
      companiesTab: 'Companies',
      structureTab: 'Company Structure',
      officesTab: 'Offices',
      jobTitlesTab: 'Job Titles',
      projectGroupsTab: 'Project Groups',
      newCompanyButton: 'New company',
    },

    companyForm: {
      edit: 'Edit: ',
      create: 'Create company',
      cancel: 'Cancel',
      save: 'Save',
      nameLabel: 'Company Name',
      namePlaceholder: 'Enter a company name',
      parentCompanyLabel: 'Parent Company',
      parentCompanyPlaceholder: 'No parent company',
      orgNumberLabel: 'Organisation Number',
      orgNumberPlaceholder: 'Enter organisation number',
      emailLabel: 'Email',
      emailPlaceholder: 'Enter an email address',
      phoneLabel: 'Phone',
      phonePlaceholder: 'Enter a phone number',
      addressLabel: 'Address',
      addressPlaceholder: 'Enter an address',
      cardColorLabel: 'Main color',
      cardColorLabel2: 'Button Color',
    },

    office: {
      createTitle: 'Create Office',
      editTitle: 'Edit Office',
      nameLabel: 'Office Name',
      newOfficeButton: 'New Office',
      errorLoadingOffices: 'Error loading offices',
    },

    jobTitle: {
      createTitle: 'Create Job Title',
      editTitle: 'Edit Job Title',
      nameLabel: 'Job Title',
      newJobTitleButton: 'New Job Title',
      errorLoadingJobTitles: 'Error loading job titles',
    },

    groupProject: {
      createTitle: 'Create Project Group',
      editTitle: 'Edit Project Group',
      nameLabel: 'Project Group Name',
      newGroupButton: 'New Project Group',
      errorLoadingGroups: 'Error loading Project Groups',
    },

    loading: {
      loading: 'Laddar...',
      errorLoadingQuiz: 'Error while loading Quiz',
      errorLoadingLibrary: 'Error while loading library',
      errorLoadingHandbook: 'Error while loading handbook',
      errorLoadingCourses: 'Error while loading courses',
      errorLoadingInvestigation: 'Error while loading risk assessment',
      errorLoadingActionsPlan: 'Error while loading actions',
      errorLoadingClaims: 'Error while loading reporting',
      errorLoadingVirtualAssistant: 'Error while loading AI Assistant',
      errorLoadingInstructions: 'Error while loading Instructions',
      errorLoadingSupport: 'Error while loading Support',
      errorLoadingDashboard: 'Error while loading Dashboard',
      errorLoadingUsers: 'Error while loading users',
      errorLoadingcompany: 'Error while loading company',
    },

    chooseHere: 'Choose here',
    create: 'Create',
    save: 'Save',
    company: 'Company',

    userRole: {
      super_admin: 'Super Admin',
      admin: 'Administrator',
      power_user: 'Power User',
      course_manager: 'Course Manager',
      manager: 'Manager',
      content_manager: 'Content Manager',
      team_leader: 'Team Leader',
      user: 'User',
    },

    userStatus: {
      active: 'Active',
      inactive: 'Inactive',
    },

    userList: {
      name: 'Name',
      email: 'Email',
      role: 'Role',
      status: 'Status',
      action: 'Actions',
      deleteConfirmation: 'Are you sure you want to delete this user?',
      edit: 'Edit',
      deactivate: 'Deactivate',
      activate: 'Activate',
      delete: 'Delete',
    },

    imageUploader: {
      chooseFile: 'Choose file',
      choosePhoto: 'Choose photo',
      drag: 'Or drag and drop ',
      here: 'here',
      photo: 'photo',
      document: 'document',
    },

    profile: {
      buttonProfile: 'Profile',
      buttonCertificates: 'Certificates',
      buttonDocuments: 'Documents',
    },

    courses: {
      title: 'Courses',
      manageCourses: 'Manage courses',
      newCourseButton: 'New course',
      titleLabel: 'Title',
      statusLabel: 'Status',
      startedLabel: 'Started',
      expiredLabel: 'Expired',
      actionsLabel: 'Actions',
      returnCourse: 'Return to the Course',
      completeLesson: 'Complete Lesson',
      copyToCompany: 'Copy course to other company',
      chooseLabel: 'Choose Companies',
      multiSelectLabel: 'Select Companies',
      copy: 'Copy',
    },

    createEditCourse: {
      title: 'Create course',
      titleLabel: 'Title',
      decsLabel: 'Description',
      subComponyLabel: 'Subsidiary Companies',
      cancel: 'Cancel',
      publish: 'Publish',
      create: 'Create',
      addLesson: 'Add leasson',
      newLesson: 'New leasson',
      courseDetails: 'Course details',
      lessons: 'Lessons',
      quiz: 'Quiz',
      participants: 'Participants',
      schedule: 'Schedule',
      courseAlert: 'Fill all fields to publish',
    },

    lessons: {
      pageTitle: 'Lessons',
      title: 'Title',
      actions: 'Actions',
      titlePlaceholder: 'Enter a lesson title',
      addLesson: 'Add lesson',
      newLesson: 'New lesson',
      emptyLesson: 'No created lessons. Create a new lesson to continue',
      cancel: 'Cancel',
      back: 'Back',
      edit: 'Edit',
      view: 'View',
      save: 'Save',
      editLesson: 'Edit lesson',
      createLesson: 'Create lesson',
      viewOnlyCourse: 'Select lesson to the left to preview',
      attachLesson: 'Attach a new lesson',
      chooseLabel: 'Choose a Lesson',
      add: 'Add',
      chooseHere: 'Choose here',
    },

    quiz: {
      title: 'Quiz details',
      noQestions: 'No created questions. Create a new question to continue',
      newQuestion: 'New question',
      addQuestion: 'Add question',
      titleLabel: 'Title',
      titlePlaceholder: 'Enter quiz title',
      passLabel: 'Passing percentage',
      passPlaceholder: 'Enter passing percentage',
      attemptLabel: 'Attempts',
      attemptPlaceholder: 'Enter number of attempts',
      create: 'Create',
      save: 'Save',
      editQestion: 'Edit question',
      createQestion: 'Create question',
      qestionLabel: 'Question',
      qestionPlaceholder: 'Type question here',
      correct: 'Correct',
      choice: 'Choice',
      actions: 'Actions',
      questions: 'Choices',
      newChoice: 'New choice',
      users: 'All users',
      returnCourse: 'Return to course',
      yourScore: 'Your score',
      isPassed: 'Congratulations! You have successfully passed the quiz.',
      isFailed: 'Sorry, you have failed the quiz.',
      question: 'Question',
      needScore: 'You need a score of',
      toPass: 'to pass this quiz. Good luck!',
      selectAnswears: 'Please select the answers that apply:',
      prvsQuestion: 'Previous question',
      nextQuestion: 'Next question',
      sendQuiz: 'Send quiz',
      choicePlaceholder: 'Type choice here',
    },

    participants: {
      title: 'Edit participants',
      department: 'Department/Business Unit/Team',
      office: 'Office',
      jobTitle: 'Job Title',
      groupProject: 'Project Group',
      user: 'User',
      isMandatory: 'Is mandatory',
      save: 'Save Changes',
    },

    schedule: {
      title: 'Edit Schedule',
      startedAt: 'Start Date',
      expiredAt: 'End Date',
      saveChanges: 'Save Changes',
      noEndDate: 'No End Date',
    },

    library: {
      searchPlaceholder: 'Search',
      newLibraryButton: 'New library',
      title: 'Create library',
      edit: 'Edit: ',
      cancel: 'Cancel',
      publish: 'Publish',
      save: 'Save',
      titleLabel: 'Title',
      titlePlaceholder: 'Enter a library title',
      describtionLabel: 'Description',
      descriptionPlaceholder: 'Enter a library description',
      cardColorLabel: 'Card color',
      cardColorPlaceholder: 'Choose library card color',
      company: 'Company',
      releaseDate: 'Release date',
      userRoll: 'User roles',
      goBackLibrary: 'BACK TO LIBRARY LIST',
    },

    certificates: {
      title: 'Your uploaded certificates',
      fileName: 'File Name',
      uploadDate: 'Upload Date',
      actions: 'Actions',
      delete: 'Delete',
      noCertificates: 'No certificates uploaded yet',
      upload: 'Upload new certificate',
      uploading: 'Uploading...',
      uploadd: 'Upload File',
    },

    documents: {
      noDocuments: 'No documents uploaded yet',
      upload: 'Upload new document',
    },
    
    investigation: {
      searchPlaceholder: 'Search',
      newInvestigationButton: 'New page',
      goBackToInvestigation: 'BACK TO RISK ASSESSMENT LIST',
    },

    investigationForm: {
      edit: 'Edit: ',
      create: 'Create page',
      cancel: 'Cancel',
      publish: 'Publish',
      save: 'Save',
      titleLabel: 'Title',
      titlePlaceholder: 'Enter a handbook title',
      descriptionLabel: 'Description',
      descriptionPlaceholder: 'Enter a handbook description',
      contentLabel: 'Content',
      contentPlaceholder: 'Enter a handbook content',
      cardColorLabel: 'Card color',
      cardColorPlaceholder: 'Enter a handbook card color',
      departmentLabel: 'Department/Business Unit/Team',
      officeLabel: 'Office',
      jobTitleLabel: 'Job Title',
      groupProjectLabel: 'Group',
      userLabel: 'User',
      checkBox: 'All users',
      placeholderMultiSelect: 'Select...',
    },

    claim: {
      searchPlaceholder: 'Search',
      newClaimButton: 'New page',
      goBackToClaim: 'BACK TO REPORTING LIST',
    },

    claimForm: {
      edit: 'Edit: ',
      create: 'Create page',
      cancel: 'Cancel',
      publish: 'Publish',
      save: 'Save',
      titleLabel: 'Title',
      titlePlaceholder: 'Enter a handbook title',
      descriptionLabel: 'Description',
      descriptionPlaceholder: 'Enter a handbook description',
      contentLabel: 'Content',
      contentPlaceholder: 'Enter a handbook content',
      cardColorLabel: 'Card color',
      cardColorPlaceholder: 'Enter a handbook card color',
      departmentLabel: 'Department/Business Unit/Team',
      officeLabel: 'Office',
      jobTitleLabel: 'Job Title',
      groupProjectLabel: 'Group',
      userLabel: 'User',
      checkBox: 'All users',
      placeholderMultiSelect: 'Select...',
    },

    actionsPlan: {
      goBackToActionsPlan: 'BACK TO ACTIONS LIST',
    },
    
    virtualAssistant: {
      create: 'Create Virtual Assistant',
      goBackToVirtualAssistant: 'BACK TO AI ASSISTANT LIST',
    },

    instructions: {
      create: 'Create Instructions',
      goBackToInstructions: 'BACK TO INSTRUCTIONS LIST',
    },

    support: {
      create: 'Create Support',
      goBackToSupport: 'BACK TO SUPPORT LIST',
    },

    hero: {
      description:
       'Work Environment Management contributes to employee safety and well-being, commitment as well as performance. For an efficient work environment management, a systematic approach is essential. At its core it includes consistently investigations, risk assessments, implementation of measures and evaluations of taken measures. In short, a systematic work environment is about creating a sustainable workplace where both the organization and its employees can thrive.',
      header: 'Welcome to the ',
      subHeaderHandbook: 'Here you will find Handbooks.',
      subHeaderCourses: 'Here you will find Courses.',
      subHeaderLibrary: 'Here you will find Library.',
      subHeaderInvestigations: 'Here you will find Risk Assessment.',
      subHeaderActionsPlan: 'Here you will find Actions.',
      subHeaderClaims: 'Here you will find Reporting.',
      subHeaderVirtualAssistant: 'Here you will find AI Assistant.',
      subHeaderInstructions: 'Here you will find Instructions.',
      subHeaderDashboard: 'Here you will find Dashboard.',
      subHeaderSupport: 'Here you will find Support.',
      header_22: ', welcome to the ',
      name: 'Work Environment Portal',
    },

    errorLoadingUsers: 'Error loading users',
  
    users: {
      searchPlaceholder: 'Search',
      newUserButton: 'New user',
      edit: 'Edit user',
      create: 'Create user',
      cancel: 'Cancel',
      save: 'Save',
      firstname: 'First Name',
      firstnamePlaceholder: 'Type here',
      lastname: 'Last Name',
      lastnamePlaceholder: 'Type here',
      username: 'Username',
      usernamePlaceholder: 'Type here',
      email: 'Email Address',
      emailPlaceholder: 'Type here',
      locale: 'Language',
      localePlaceholder: 'Choose here',
      role: 'Role',
      rolePlaceholder: 'Choose here',
      department: 'Department',
      departmentPlaceholder: 'Choose here',
      office: 'Office',
      officePlaceholder: 'Choose here',
      collar: {
      blue: 'Blue collar',
      white: 'White collar',
      },

    roleDescriptions: {
        super_admin: 'Super Admin',
        admin: 'Administrator',
        power_user: 'Power User',
        course_manager: 'Course Manager',
        manager: 'Manager',
        content_manager: 'Content Manager',
        team_leader: 'Team Leader',
        user: 'User',
    },
    },
  },
}

export default en
